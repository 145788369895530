/**
 *  EN language file
 */

const lang_fr = {
    signup: "S'enregistrer",
    login: "Connexion",
    calls: "Projets",
    myaccount: "Mon compte",
    logout: "Déconnexion",

    copyright: "Copyright\u00A0\u00A92023\u00A0PVD+ Tous\u00A0droits\u00A0résérvés",
    contactus: "Contactez-nous",
    contactEmail: "guillaume.malite@technopolis-group.com",
    contactEmailCC: "cristina.wallez-cuevas@technopolis-group.com",
    contactEmailSubject: "PVD+%20:%20Information",
    privacyPolicies: "Politique de confidentialité",
    privacyLink: "https://www.technopolis-group.com/fr/privacy-policy/",

    hello: "Bonjour",


    createAccount: "Créer un compte",
    accountNeeded: "Vous aurez besoin d'un compte pour pouvoir soumettre un formulaire de sollicitation d'accompagnement PVD+.",
    fillForm: "Veuillez remplir le formulaire ci-dessous.",

    // signup
    name: "Prénom",
    surname: "Nom",
    email: "Email",
    password: "Mot de passe",
    confirmPassword: "Confirmez le mot de passe",
    privacyPolicyName: "politique de confidentialité",
    privacyBefore: "En cochant cette case, vous acceptez la",
    submit: "Envoyer",

    phone: "Téléphone",
    accountAlready: "Vous avez déjà un compte?",
    goToLogin: "Connectez-vous",

    required: "Requis",
    invalidEmail: "Email non valide",
    passwordMinBefore: "Votre mot de passe doit contenir au moins",
    passwordMinAfter: "caractères et contenir au moins 1 lettre minuscule, 1 lettre majuscule et 1 chiffre",
    passwordMismatch: "Les mots de passe ne correspondent pas",
    errorOccured: "Une erreur est survenue",

    sorry: "Désolé,",
    pageNotExist: "cette page n'existe pas",

    somethingWrong: "Une erreur est survenue\nVeuillez réessayer",
    emailValidation: "Validation de votre email",
    emailValidated: "Votre adresse email  a été validée et vous pouvez maintenant vous connecter à votre compte",
    emailValidationRedirect: "Vous allez etre redirigé",

    // reset password
    passwordResetTitle: "Réinitialisation de votre mot de passe",
    passwordResetMessage: "Si vous ne pouvez pas vous connecter à votre compte, nous pouvons vous envoyer un lien de réinitialisation.",
    passwordChanged: "Votre mot de passe a été modifié, vous pouvez maintenant vous connecter à votre compte",
    chooseNewPassword: "Choisissez votre nouveau mot de passe",

    //login 
    invalidCredentials: "Email ou mot de passe incorrect",
    accountDisabled: "Votre compte a été désactivé. Vous devez modifier votre mot de passe.",
    loginTitle: "Connexion",
    loginEmailValidated: "Félicitations! Votre adresse email a été validée. Connectez-vous pour soumettre un formulaire de sollicitation d'accompagnement PVD+",
    loginMessage: "Connectez-vous à votre compte",
    loginForgotPassword: "Vous avez oublié votre mot de passe?",
    loginNoAccountStart: "Si vous n'avez pas de compte, vous pouvez",
    loginNoAccountEnd: "vous inscrire ici.",

    // email validation
    emailValidationMessage: "Un email de validation a été envoyé sur votre adresse email.",
    emailValidationCheckSpam: "Veuillez vérifier votre dossier Spam avant d'effectuer une nouvelle demande.",
    emailValidationRequired: "Vous devez valider votre adresse email pour pouvoir soumettre une candidature.",
    emailValidationRequest: "Si vous n'avez pas reçu d'email de validation, vous pouvez en demander un nouveau.",
    emailValidationRequestButton: "Envoyez moi un email",

    // account
    dashboard: "Tableau de bord",
    yourApplications: "Vos sollicitations d’accompagnement PVD+",
    yourProfile: "Votre profil",
    changePassword: "Modifier votre mot de passe",
    needConfirm: "Pour soumettre un candidature à un projet, vous devez d'abord confirmer votre adresse email. Nous vous avons envoyé un email avec un lien permettant de valider votre adresse email. Si vous ne l'avez pas reçu, vérifiez votre dossier Spam ou:",
    resendEmail: "Renvoyez moi un email",

    youHave: "Vous avez",
    incompleteApplications: "sollicitations incomplètes",
    incompleteApplication: "sollicitation incomplète",
    editApplications: "Complétez vos sollicitations",
    noOpenedApplication: "Vous n'avez aucune sollicitation en cours",
    editProfile: "Mettez à jour votre profil et vos informations",
    editProfileButton: "Modifier mon profil",

    contactQuestion: "Une question ? Besoin d'aide, n'hésitez pas à nous écrire.",

    applicationsInProgress: "Sollicitation(s) d’accompagnement PVD+ en cours de soumission",
    noApplicationsInProgress: "Vous n’avez aucune sollicitation en cours de soumission",
    applicationsCompleted: "Sollicitation(s) d’accompagnement PVD+ complétée(s)",

    deleteYourProject: "Supprimer cette sollicitation",
    areYouSure: "Etes-vous certain?",
    open: "Ouvert",
    closed: "Fermé",
    edit: "Modifier",
    delete: "Supprimer",
    viewSubmission: "Voir ma solicitation",
    cancelDeleteProject: "Non",
    confirmDeleteProject: "Oui",

    addPhone: "Ajouter un numéro de téléphone",
    editProfileTitle: "Modifiez votre profil",

    passwordUpdated: "Votre mot de passe a été mis-à-jour",
    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmNewPassword: "Confirmez votre nouveau mot de passe",

    result_submitted: "Soumis",
    result_accepted: "Accepté",
    result_rejected: "Rejeté",

    // home
    confirmationLinkSent: "Lien de confirmation envoyé!",
    confirmationLinkMessage: "Nous vous avons envoyé un email contenant un lien de confirmation.",
    checkSpam: "Si vous ne l'avez pas reçu, vérifiez votre dossier spam!",
    passwordLinkSent: "Lien de réinitialisation envoyé!",
    passwordLinkMessage: "Nous vous avons envoyé un email contenant un lien pour réinitialiser votre mot de passe.",
    applicationSubmitted: "Formulaire de sollicitation d’accompagnement PVD+ envoyé !",
    applicationMessage: "Votre formulaire de sollicitation d’accompagnement PVD+ a été envoyé à notre équipe.",
    applicationInfo: "Notre équipe analysera votre formulaire et vous informera par email du résultat de l’exercice de qualification de votre besoin.",
    processLength: "Le processus d'analyse peut prendre quelques semaines.",
    goDashboard: "Aller sur mon tableau de bord",

    callsForProposals: "Formulaire de sollicitation d'accompagnement PVD+", // "Appel à projets"
    allCalls: "Tous les projets",
    openCalls: "Projets ouverts",

    hour: "heure",
    day: "jour",
    week: "semaine",
    month: "mois",
    months: "mois",
    openingIn: "Ouvert dans",
    left: "restant[es]",
    leftMS: 'restant',
    leftMP: 'restants',
    leftFS: 'restante',
    leftFP: 'restantes',

    loginToApply: "Connectez-vous",
    confirmYourEmail: "Confirmer votre email",
    editSubmission: "Continuer",
    applyNow: "Participer maintenant",
    // readMore: "En savoir plus",
    readMore: 'En savoir plus sur le dispositif et les criteres d’eligibilte',
    description: "Description",
    all: "Tous",

    // form participate
    missingTab: "Votre formulaire n’est pas encore complet et ne peut donc pas encore être soumis. Merci de vous assurer que vous avez bien répondu à chacune des questions obligatoires.",
    showMissingAnswers: "Mettre en évidence les réponses manquantes",
    save: "Sauvegarder",
    submitParticipate: "Envoyer",
    previous: "précédent",
    next: "suivant",
    somethingWrongWhileSaving: "Une erreur est survenue en sauvant votre formulaire.",
    unsavedModifications: "Vous avez effectué des modifications qui ne sont pas sauvegardées",
    sureLeaving: "Etes-vous sûr de vouloir continuer ?",
    leaveWithoutSave: "Continuer sans sauvegarder",
    saveAndLeave: "Sauvegarder & continuer",
    submitYourApplication: "Soumettre votre formulaire de sollicitation d’accompagnement PVD+",
    submitFormMessage: "Votre formulaire de sollicitation d’accompagnement PVD+ est maintenant complet et prêt à être envoyé. Une fois que vous aurez soumis votre formulaire, vous pourrez le télécharger au format PDF. Notre équipe analysera votre formulaire et vous informera par email du résultat de l’exercice de qualification de votre besoin.",
    warning: "Attention",
    submitFormWarning: "Avant de soumettre votre formulaire, vérifiez que toutes les informations sont correctes. Vous ne pourrez plus modifier votre formulaire une fois envoyé.",
    submitMyApplication: "Soumettre mon formulaire de sollicitation d’accompagnement PVD+",
    submitConfirm: "Je confirme que toutes les informations sont complètes et correctes.",

    selectFile: "Sélectionner votre fichier",
    fileSizeTooLarge: "Fichier trop volumineux",
};

export default lang_fr;
